<!--
 * @Description: 教务编辑
 * @Author: xiawenlong
 * @Date: 2021-04-23 16:23:28
 * @LastEditors: xiawenlong
 * @LastEditTime: 2021-05-24 15:33:11
-->
<template>
  <div class="student-list-edit">
    <el-card>
      <div slot="header" class="clearfix">
        <span class="title">编辑教务</span>
      </div>
      <el-form ref="studentForm" :model="studentForm" label-width="100px" :rules="rules">
        <el-form-item label="教务姓名" prop="studentName">
          <el-input v-model="studentForm.studentName" placeholder="请输入姓名" clearable></el-input>
        </el-form-item>
        <el-form-item label="教务手机号">
          <el-input
            v-model="studentForm.mobile"
            disabled
            placeholder="请输入手机号"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item label="身份证">
          <el-input v-model="studentForm.cardNo" placeholder="请输入身份证" clearable></el-input>
        </el-form-item>
        <!-- <el-form-item label="部门">
          <el-select v-model="studentForm.depts" multiple placeholder="请选择部门" clearable>
            <el-option
              v-for="item in deptList"
              :key="item.deptId"
              :label="item.deptName"
              :value="item.deptId"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="班级">
          <el-select v-model="studentForm.classes" multiple placeholder="请选择班级" clearable>
            <el-option
              v-for="item in classList"
              :key="item.classId"
              :label="item.className"
              :value="item.classId"
            >
            </el-option>
          </el-select>
        </el-form-item> -->
        <el-form-item ref="headPic" label="教务头像" prop="headPic">
          <upload-image v-model="studentForm.headPic" :upload-img="$refs.headPic"></upload-image>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" @click="update">保存</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>
<script>
import { educationalInfo, educationalEdit } from '@/api/course'
import to from 'await-to'
import UploadImage from '@/components/UploadImage'
export default {
  name: 'StudentListEdit',
  components: { UploadImage },
  data() {
    return {
      rules: {
        studentName: [{ required: true, message: '请输入教务姓名', trigger: 'blur' }],
      },
      studentForm: {},
      loading: false,
    }
  },
  mounted() {
    this.getStudentInfo()
  },
  methods: {
    async getStudentInfo() {
      const { studentId } = this.$route.params
      const [res, err] = await to(educationalInfo({ studentId }))
      if (err) return this.$message.warning(err.msg)
      this.studentForm = res.data
    },
    update() {
      this.$refs['studentForm'].validate(async valid => {
        if (valid) {
          this.loading = true
          const [, err] = await to(educationalEdit(this.studentForm))
          this.loading = false
          if (err) return this.$message.warning(err.msg)
          this.$message.success('编辑成功')
          this.$router.push('/college/educational')
        }
      })
    },
  },
}
</script>
